@import 'variables';

h1, h2 {
  color: var(--b4a-brand-color);
}

p {
  color: rgba($color: #000000, $alpha: .85);
  margin: 0;
  font-size: 0.7rem;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
