@media (max-width: 768px) {
  /* 80px and its content aligned to centre. Pushing it off the screen with the
     negative left margin
  */
  #sidebar.active {
    min-width: 80px;
    max-width: 80px;
    text-align: center;
    margin-left: -80px !important;
  }

  /* Reappearing the sidebar on toggle button click */
  #sidebar {
    margin-left: 0;
  }

  /* Toggling the sidebar header content,
     hide the big heading [h3] and showing the small heading [strong] and vice versa
  */
  #sidebar .sidebar-header strong {
    display: none;
  }
  #sidebar.active .sidebar-header h3 {
    display: none;
  }
  #sidebar.active .sidebar-header strong {
    display: block;
  }

  /* Downsize the navigation links font size */
  #sidebar.active ul li a {
    padding: 20px 10px;
    font-size: 0.85em;
  }

  #sidebar.active ul li a i {
    margin-right: 0;
    display: block;
    font-size: 1.8em;
    margin-bottom: 5px;
  }

  /* Adjust the dropdown links padding*/
  #sidebar.active ul ul a {
    padding: 10px !important;
  }

  /* Changing the arrow position to bottom center position,
    translateX(50%) works with right: 50%
    to accurately  center the arrow */
  .dropdown-toggle::after {
    top: auto;
    bottom: 10px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
  }
}