.class-table {
  background: #fff;
  border-radius: 4px;
  padding: 10px;
  @extend .shadow;

  .vertical-centered {
    vertical-align: middle;
  }

  th, td {
    white-space: nowrap;
    vertical-align: baseline;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0.75rem 1.1rem 0.75rem 0.75rem!important;

    @media (max-width: 768px) {
      max-width: 280px;
    }

    @media (min-width: 769px) {
      max-width: 360px;
    }

    > a {
      color: var(--b4a-brand-color);

      > img {
        height: 80px;
        width: 80px;
      }
    }
  }

  th {
    color: var(--b4a-brand-color);
    font-size: 14px;

    div.header-content {
      display: flex;
      justify-content: space-between;

      > span {
        width: 90%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      > i {
        float: right;
        margin-top: 3px;
        margin-left: 20px;
      }
    }

    li  {
      padding-left: 1rem;

      span, span > i{
        padding: 0 5px;
      }

      i {
        font-size: 16px;
      }
    }

    .fa-filter {
      margin-bottom: 1px;
    }

    .dropdown-item {
      cursor: pointer;
    }

  }

  @media (max-width: 768px) {
    width: calc(100% - 20px) !important;
  }

  @media (min-width: 769px) {
    width: calc(100% - 80px) !important;
  }
}

.table-main-title {
  //color:var(--b4a-brand-color);
  font-weight: 600;
  color: rgba($color: #000000, $alpha: .85);
  text-align: center;
  width: 100%;
}

.header {
  margin: 25px 0;
  display: inline-flex;
  position: relative;
}

.table-responsive {
  max-height: 70vh;
  overflow: auto;
}

tbody {
  font-size: .9em;
}

.icon-enabled, li.icon-enabled > svg {
  color: var(--b4a-brand-color);
}

.icon-disabled, li.icon-disabled > svg {
  color: $icon-off;
}
