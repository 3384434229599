.table-header {
  display: table-cell !important;
  cursor: pointer;

  .dropdown-item {
    .zmdi, svg {
      width: 14px;
    }
  }
}
