.form-check-input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  height: 20px;

  +label {
    cursor: pointer;

    &:before {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border: 3px solid #0000;
      left: 5px;
      top: 10px;
      transform: rotateZ(40deg);
      transition: all .2s .1s;
      z-index: 1;
    }

    &:after {
      position: absolute;
      content: '';
      height: 16px;
      width: 16px;
      border: 2px solid #5a5a5a;
      top: 3px;
      left: 0;
      transition: border .25s, background-color .25s;
      border-radius: 4px;
    }
  }

  &:checked+label {
    &:before {
      top: 2px;
      width: 7px;
      height: 12px;
      border-top: 2px solid #0000;
      border-left: 2px solid #0000;
      border-right: 2px solid #fff;
      border-bottom: 2px solid #fff;
    }

    &:after {
      border: 2px solid var(--b4a-brand-color);
      background-color: var(--b4a-brand-color);
    }
  }
}
