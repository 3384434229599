.login {
  height: 100vh;
  justify-content: space-evenly;

  .login-card {
    h2 {
      text-align: center;
    }
    padding: 40px 20px;
    border-radius: 5px;
    @extend .shadow;
    background: #fff;
    width:400px;
  }

  @media (max-width:500px){
    .login-card {
      max-width: 90%;
      padding: 32px 18px;

      h2 {
        font-size: 1.6rem;
      }
    }
  }

  .container-logo {
    display: flex;
    justify-content: center;
  }

  .image-logo {
    max-width: 330px;
    max-height: 200px;
  }

  .form-group {
    label {
      margin-bottom: 3px;
    }
  }

  .btn {
    background-color: var(--b4a-brand-color) !important;
    border-color: var(--b4a-brand-color) !important;
  }

  .footer {
    font-size: .9rem;
    text-align: center;
    color: rgba(255, 255, 255, .5);

    a {
      color: rgba(255, 255, 255, .5);
    }
    .footer-icon{
      position: relative;
      bottom:-3px;
    }
  }
}
