@import 'variables';

$form-title-height: 91px;
$form-toolbar-height: 49px;
$form-body-height: calc(100vh - #{$reserved-height} - #{$form-title-height} - #{$form-toolbar-height});

.react-datepicker-popper {
  z-index: 1000;
}

.content-container {
  .form-container {
    display: flex;
    justify-content: center;

    .class-table {
      width: 75vw;
      max-width: 1130px !important;
      margin: unset;
      padding: 10px 0;

      .admin-form-groups-container {
        height: 100%;
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        hr {
          margin:10px 0;
        }

        h5 {
          font-size: 1.1rem;
          padding: 0 20px;
        }

        label {
          font-size: 0.9rem;
        }

        h5, label {
          font-weight: bold;
        }

        form .row {
          margin: 0 5px;

          .geo-query-form {
            &:first-of-type {
              @media (max-width:768px) {
                margin-right: 10%;
              }

              @media (min-width:769px) {
                margin-right: 5%;
              }
            }

            @media (max-width:768px) {
              width: 45%;
            }

            @media (min-width:769px) {
              width: 25%;
            }
          }
        }

        .form-body {
          max-height: $form-body-height;
          overflow-y: auto;
          padding-top: 3px;
        }

        .form-control {
          height: calc(2rem + 2px);

          &:focus {
            border-color: white;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px var(--b4a-brand-color);
          }
        }

        .btn {
          font-weight: bold;
          font-size: 0.9rem;
          padding: 0.2rem .75rem;
        }

        .alert-danger {
          margin-bottom: unset;
        }
      }
    }
  }
}
