.admin-notification {
  color: black;
  font-size: 14px;
  min-height: 45px;
  border-radius: 5px;
  padding: 15px;
  top: 0;

  @media (max-width: 768px) {
    right: 0;
  }

  @media (min-width: 769px) {
    right: 20px;
  }


  button {
    margin: 0;
  }

  span > i, span > svg {
    color: var(--b4a-brand-color);
    margin-right: 10px;
    font-size: 16px;
  }
}

.danger {
  span > i, span > svg {
    color: $danger;
  }
}

.success {
  span > i, span > svg {
    color: $success;
  }
}
