td, th, div {
  div.hover {
    display: none;
  }

  div.hover.show {
    position: absolute;
    z-index: 1060;
    display: block;
    min-width: 180px;
    text-align: left;
    text-align: start;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .9em;
    word-wrap: break-word;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    padding: .5rem .75rem;
    background-color: rgba(0, 0, 0, .85);
    color: white;

    @media (max-width: 768px) {
      max-width: 280px;
    }

    @media (min-width: 769px) {
      max-width: 360px;
    }

    .hover-title {
      width: 100%;
      height: 15px;
      margin-bottom: 5px;
      margin-top: 5px;

      > i {
        float: right;
        cursor: pointer;
      }
    }

    .hover-content {
      max-height: 350px;
      overflow: auto;

      p, pre {
        color: inherit;
        margin-bottom: 0;
        font-size: inherit;
      }

      a {
        color: white;
        text-align: center;

        > img {
          height: 150px;
          width: 150px;
          margin-bottom: 20px;
          border-radius: 4px;
        }
      }
    }

    .content-centered {
      text-align: center;
    }

    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, .85);
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: rgba(255, 255, 255, .55);
    }
  }
}