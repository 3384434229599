@import 'variables';

.shadow {
  -webkit-box-shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.28);
  box-shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.28);
}

.brand-gradient {
  background: var(--b4a-brand-color) /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#208aec+0,7db9e8+100 */
  -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.15)), to(rgba(0, 0, 0, .5)));
}

