@import 'variables';

.multiple-select {
  & > .input-group.invalid {
    border: 1px solid red;
    border-radius: 5px;
  }

  .multiple-select-add-btn {
    background-color: unset;
    border: 1px solid #ccc;
    font-weight: normal !important;
    padding: 0.2rem 0.55rem !important;
  }

  .multiple-select-plus-icon {
    color: var(--b4a-brand-color);
  }

  .selected-items-box {
    button {
      font-weight: normal !important;
    }
  }
}
