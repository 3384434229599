@import './variables.scss';

.files-label {
  display: flex;
  align-items: center;
}

.input-group {
  align-items: center;

  > img {
    height: 80px;
    width: 80px;
    margin-right: 15px;
    border-radius: 4px;
  }

  button {
    background-color: white;
  }
}

.button-input-form {
  height: calc(2rem + 6px)!important;
}

.input-group-append {
  height: calc(2rem + 2px);

  .icon {
    margin-right: 5px;
  }

  .primary {
    color: var(--b4a-brand-color);
    font-size: 17px;
  }

  .danger {
    color: $danger;
  }
}

.custom-file-label {
  &:after {
    padding: 0.475rem 0.75rem;
    background-color: white;
  }
}
