@import 'visual-effects';

$sidebar-width: 270px;
$sidebar-title-height: 155px;
$sidebar-footer-height: 62px;

.wrapper {
  display: flex;
  align-items: stretch;
  height: 100vh;
  .hide {
    display: none;
  }
  .show {
    display: block;
  }
}

#sidebar {
  overflow: hidden;
  width: $sidebar-width;
  z-index: 999;
  height: inherit;
  transition: all 0.3s;
  -webkit-box-shadow: 1px 4px 5px 0 rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 1px 4px 5px 0 rgba(0, 0, 0, 0.28);
  box-shadow: 1px 4px 5px 0 rgba(0, 0, 0, 0.28);
  background: #fff;


  ul:first-of-type {
    margin-bottom: 0;
    padding-bottom: 10px;
  }

  ul:nth-of-type(2n)  {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .sidebar-subtitle {
    color: var(--b4a-brand-color);
    font-weight: bold;
    font-size: 14px;

    + li {
      margin-top: 15px;
    }
  }
}

#sidebar.collapsed {
  margin-left: - $sidebar-width;
  box-shadow: unset;
}

.container-logo {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.image-logo {
  max-width: 160px;
  max-height: 70px;
  margin: 0px 20px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sidebar-header {
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  padding: 20px;
  h3 {
    color: var(--b4a-brand-color);
    font-size: 20px;
    font-weight: bold;
  }
  span {
    color: rgba(0, 0, 0, .5);
    font-size: 12px;
    margin-bottom: -4px;
    margin-left: 2px;
  }
}

.sidebar-list {
  padding: 20px;
  list-style: none;
  font-size: 16px;

  .active {
    font-weight: bold;
    .nav-link {
      color: rgba(0, 0, 0, .7);
    }
  }

  .nav-link {
    color: rgba(0, 0, 0, .5);
    padding: .4rem 1rem;
  }
}

.sidebar-content {
  max-height: calc(100vh - #{$sidebar-title-height} - #{$sidebar-footer-height});
  overflow: auto;
}

#content {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

.sidebar-btn-container {
  float: right;
}

.sidebar-btn-container-logo {
  display: flex;
  justify-content: flex-end;
}

#sidebar-btn-collapse {
  position: relative;
  padding: 0;
  width: 45px;
  height: 25px;
  font-size: 12px;
  background-color: var(--b4a-brand-color);
  border-color: var(--b4a-brand-color);
}

.sidebar-collapse-button-container {
  z-index: 9;
  position: absolute;

  &.hide {
    margin-left: -270px;
  }

  #sidebarCollapse {
    background-color: var(--b4a-brand-color);
    border-color: var(--b4a-brand-color);
  }
}

.sidebar-footer {
  display: flex;
  justify-content: flex-end;
  padding: 15px 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  .btn-primary {
    background-color: var(--b4a-brand-color);
    border-color: var(--b4a-brand-color);
  }
}

.sidebar-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  transition: background-color ease-in 0.3s;

  &.sidebar-overlay-fade-enter-active, &.sidebar-overlay-fade-enter-done {
    background-color: rgba(0,0,0,0.5);
  }
}

// ====================== MEDIA QUERIES ======================

@media (max-width: 768px) {
  #sidebar {
    margin-left: - $sidebar-width;
    position: fixed;
    top: 0;
    left: 0;
  }
  .sidebar-btn-container {
    display: block;
  }

  #sidebarCollapse {
    margin: 20px 0 5px 10px;
  }
}

@media (min-width: 769px) {
  .sidebar-header {
    position: relative;
  }

  #sidebarCollapse {
    margin: 20px 0 5px 40px;
  }
}
