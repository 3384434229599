
.objects-filter-modal {
  font-size: 0.9rem;

  .form-control, .btn {
    font-size: 0.9rem;
  }

  .form-control:focus {
    border-color: white;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px var(--b4a-brand-color);
  }

  .selected-items-box {
    overflow: auto;
  }

  .modal-footer {
    .btn {
      font-weight: bold;
      font-size: 0.9rem;
      padding: 0.2rem .75rem;
    }
  }

  .modal-body {
    max-height: calc(100vh - 200px);
    overflow: auto;

    .add-field-dropdown {
      padding-right: 15px;
    }

    .react-datepicker-wrapper, .react-datepicker__input-container {
      display: block;
    }
  }

  @media (max-width: 400px) {
    .modal-body {
      .row {
        .col-1 {
          padding-left: 8px;
          padding-right: 12px;
        }
      }
    }
  }
}

.filter-button {
  @media (max-width: 768px) {
    display: none;
  }
}
