.home {
  height: 100%;

  .home-content-container {
    max-width: 1130px;
    padding: 20px;
    box-shadow: 0rem 0rem 1.2rem rgba(0, 0, 0, 0.15) !important;

    .home-play-icon {
      font-size: 32px;
      color: var(--b4a-brand-color);
      line-height: 28px;
    }

    h4 {
      font-size: 20px;
      font-weight: bold;
    }

    h5 {
      font-size: 16px;
      margin-left: 29px;
    }

    .home-video-container {
      margin: 30px;
    }
  }
}
