.json-editor {
  &.is-invalid {
    border: 1px solid #D0021B;

    .jsoneditor {
      border-color: #D0021B;
    }

    .jsoneditor-menu {
      background-color: #D0021B;
    }
  }
}
