.full-text-input-group {
  display: inline-flex;
  top: -1px;
  padding: 0;

  .search-box {
    height: calc(2rem);

    &:focus {
      border-color: white;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px var(--b4a-brand-color);
    }
  }

  .search-input {
    font-size: 14px;
  }

  .button-text {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .button-icon {
    @media (min-width: 769px) {
      margin-right: 0.5rem !important;
    }
  }
}
