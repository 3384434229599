@import 'variables';

$objects-toolbar-height: 52px;
$objects-pagination-height: 70px;
$objects-table-height: calc(100vh - #{$reserved-height} - #{$objects-toolbar-height} - #{$objects-pagination-height});

.breadcrumbs {
  font-size: 1rem;
  list-style: none;

  li {
    .relation-link {
      color: unset;
    }

    .object-id {
      color: #888;
    }

    &.current-context {
      color: var(--b4a-brand-color);
      font-weight: bold;
    }
  }

  @media (max-width: 768px) {
    width: calc(100% - 20px) !important;
  }

  @media (min-width: 769px) {
    width: calc(100% - 80px) !important;
  }
}

.objects {
  height: $content-container-max-height;
  max-height: $content-container-max-height;

  .header-toolbar {
    display: flex;
    justify-content: space-between;
    padding-right: 0;
    padding-left: 0.5rem;

    .btn {
      line-height: 1.1;
      font-size: 0.92rem;
      font-weight: bold;

      padding: 0.375rem 0.5rem;

      &.btn-primary {
        background-color: var(--b4a-brand-color);
        border-color: var(--b4a-brand-color);
      }
    }

    .vertical-row {
      border-left: 1px solid #0003;
      width: 1px;
      height: 29px;
      display: inline-block;
      vertical-align: middle;

      @media (max-width: 768px) {
        display: none;
      }
    }

    @media screen and (max-width: 568px) {
      padding: 0;
    }

    .buttons-wrapper {
      margin-left: auto;
    }
  }

  .table-responsive {
    height: $objects-table-height;
    max-height: $objects-table-height;

    .pointer-type {
      a {
        color: var(--b4a-brand-color);
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .relation-link {
      color: var(--b4a-brand-color);
    }
  }

  .pagination {
    .page-item {
      &:not(.disabled):not(.active):not(:first-of-type):not(:last-of-type) {
        .page-link {
          color: black;
        }
      }

      &.active > .page-link {
        box-shadow: 0 0 3px 0px black;
        background-color: var(--b4a-brand-color);
        border-color: var(--b4a-brand-color);
        color: white;
      }
    }

    .page-link {
      padding: 0.38rem 0.65rem;
      font-size: .95rem;
      border: unset;
      color: var(--b4a-brand-color);
    }
  }

  .ellipsis-button {
    display: inline-block;
    position: relative;
    margin-left: 15px;
    top: 1px;
    min-width: 10px;
    cursor: pointer;
  }

  .ellipsis-dropdown {
    font-size: 14px;
    margin-top: 10px;
    box-shadow: 0rem 0rem 1.2rem rgba(0, 0, 0, 0.15);
    max-width: 33.332%;

    .ellipsis-sort-filter {
      @media (min-width: 769px) {
        display: none;
      }

      > span {
        margin-left: 11px;
        color: black;
      }
    }

    > li {
      padding: 5px 10px;
      display: inline-flex;
      width: 100%;
      cursor: pointer;
      align-items: center;

      &:last-of-type {
        @media (max-width: 768px) {
          border-top: 2px solid rgba(0, 0, 0, 0.1);
        }
      }

      span {
        margin-left: 5px
      }
    }
  }
}
