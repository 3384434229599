.boolean-input-validation {
  display: inline-block;
  border: 1px solid transparent;

  &.is-invalid {
    border-color: #D0021B;
    border-radius: 0.25rem;
  }

  .boolean-input-container {
    padding: .375rem .75rem;
    align-items: center !important;

    .vertical-row {
      border-left: 1px solid rgba(0,0,0,.2);
      width: 1px;
      height: 30px;
    }

    .custom-radio .custom-control-label::before {
      border: 1px solid #ccc;
    }

    .custom-control-inline:last-of-type {
      margin-right: 0.5rem;
    }
  }
}

.btn-toggle {
  padding: 0;
  position: relative;
  border: none;
  height: 1.4rem;
  width: 2.7rem;
  border-radius: 1.5rem;
  color: #6b7381;
  background: #bdc1c8;

  & > .handle {
    position: absolute;
    top: 0.1875rem;
    left: 0.2775rem;
    width: 1.025rem;
    height: 1.025rem;
    border-radius: 1.125rem;
    background: #fff;
    transition: left 0.25s;
  }

  &.active {
    background-color: #4CAF50;

    &.focus {
      outline: none;
    }

    > .handle {
      left: 1.4875rem;
      transition: left 0.25s;
    }
  }
}
