.delete-objects-container {
  h5 {
    padding-left: 25px;
    padding-top: 12px;
    font-size: 24px;
    font-weight: bold;
  }

  .default-padding-left {
      padding-left: 23px;
  }

  .message-container {
      font-size: 20px;
      margin-top: 25px;
  }

  .objects-container {
    border: 1px solid #979797;
    border-radius: 4px;
    background-color: #f5f5f5;
    color: #dc3545;
    font-size: 16px;
    line-height: 30px;
  }

  .btn {
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: bold;
      padding: 0.2rem .75rem;
  }

}