button {
  text-transform: uppercase;
  font-size: 14px;
}

.btn {
  z-index: unset !important;
}

.btn:focus, .btn:active:focus, .btn.active:focus {
  outline: 0 none;
}

.btn-primary {
  background: var(--b4a-brand-color) !important;
  border-color: var(--b4a-brand-color) !important;
  &:hover {
    background: var(--b4a-brand-color) !important;
    border-color: var(--b4a-brand-color) !important;
    filter: brightness(90%);
  }
  &:active {
    background: var(--b4a-brand-color);
  }
  &:focus {
    background: var(--b4a-brand-color);
  }
  &.active {
    background: var(--b4a-brand-color);
  }
}

.btn-user {
  position: absolute;
  right: 0;
  border: none;
  height: 40px;
  width: 40px;
  &:hover {
    background: rgba($color: #000000, $alpha: .1);
    border-radius: 4px;
  }

  @media (max-width: 768px) {
    margin: 25px 23px 0 0;
  }

  @media (min-width: 769px) {
    margin: 25px 65px 0 0;
  }
}

.logout-icon, .logout-icon:hover {
  color: rgba(0, 0, 0, 0.7);
}

.dropdown {
  text-align: center;

  .dropdown-item {
    text-align: center;
    font-size: 14px;
  }

  .logout-text {
    color: var(--b4a-brand-color);
    font-weight: bold;
  }
}
