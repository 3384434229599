
.objects-sort-modal {
  font-size: 0.9rem;

  .form-control, .btn {
    font-size: 0.9rem;
  }

  .form-control:focus {
    border-color: white;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px var(--b4a-brand-color);
  }

  .btn-group {
    height: 100%;

    & > label {
      height: 100%;
    }
  }

  .modal-body {
    .add-field-dropdown {
      padding-right: 15px;
    }

    .btn-secondary {
      color: black;
      border-color: #C5C5C5;
      background: #E5E5E5;
    }
  }

  .modal-footer {
    .btn {
      font-weight: bold;
      font-size: 0.9rem;
      padding: 0.2rem .75rem;
    }
  }

  @media (max-width: 400px) {
    .modal-body {
      .row {
        .col-1 {
          padding-left: 8px;
          padding-right: 12px;
        }
      }
    }
  }
}

.sort-button {
  @media (max-width: 768px) {
    display: none;
  }
}
