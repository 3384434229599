// DO NOT USE THIS FILE IF THE VARIABLE IS PART OF THE THEME.
// CHECK APP.TSX "DEFAULT_B4A_THEME" CONSTANT.

$light-gray : #f5f5f5;
$body-bg: $light-gray; // Override bootstrap body background-color

$icon-off: rgba(0, 0, 0, 0.7);

$font-family-base: 'Open Sans', sans-serif; // Override bootstrap base font-family

// bootstrap theme colors
$danger: #D0021B;
$success: #4CAF50;
$light: $light-gray;

// Vertical responsive design
$header-height: 91px;
$post-content-height: 19px;

$reserved-height: $header-height + $post-content-height;
$content-container-max-height: calc(100vh - #{$reserved-height});
